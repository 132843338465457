import PropTypes from "prop-types";
import Chip from "../chip/chip";

//TODO: define good colors```
const difficultyStyle = {
  leisure: {
    backgroundColor: "var(--color-badge-leisure)",
  },
  easy: {
    backgroundColor: "var(--color-badge-easy)",
  },
  moderate: {
    backgroundColor: "var(--color-badge-moderate)",
  },
  hard: {
    backgroundColor: "var(--color-badge-hard)",
  },
  extreme: {
    backgroundColor: "var(--color-badge-extreme)",
  },
};

export default function TripDifficulty(props) {
  const { largerText, difficulty, sx } = props;
  const vars = difficultyStyle[difficulty?.id];
  const text = difficulty?.name;

  return (
    <Chip
      title={difficulty.description}
      label={text}
      labelSx={{
        fontSize: {
          xs: "10px",
          md: largerText ? "16px" : "12px",
        },
        fontWeight: "400",
      }}
      sx={{
        padding: "4px 8px !important",
        ...vars,
        ...sx,
      }}
    />
  );
}

TripDifficulty.propTypes = {
  difficulty: PropTypes.instanceOf(Object).isRequired,
};
