import React from "react";
import { Typography, Box } from "@mui/material";
import styles from "../trip.module.css";
const TripName = ({ children, name }) => {
  return (
    <Box display="flex" flexGrow={1} className={styles.trips_header}>
      <Typography
        className={styles.tripDetail__dialog__heading}
        sx={{
          typography: { xs: "text-base-semibold", md: "text-xl-semibold" },
        }}
        component="h1"
      >
        Trip to {name}
      </Typography>
      {children}
    </Box>
  );
};

export default TripName;
