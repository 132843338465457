import React from "react";
import { Box, Typography } from "@mui/material";
import Image from "next/image";
import ShareTripButton from "./share.trip.button";
import styles from "./trip.dialog.hero.module.css";

function TripDialogHero({ tripDetails = {} }) {
  const heroImg =
    tripDetails?.itineraryDetails?.experience_media?.find(
      (media) => media?.media_type === "image"
    )?.cdn_url || tripDetails?.highlightLocationCdnUrl;

  return (
    <Box className={styles.heroImgCtn}>
      <Image layout="fill" src={heroImg} />
      <Box className={styles.heroImgCtn__nameCtn}>
        <Typography className={styles.heroImgCtn__nameCtn__name}>
          {tripDetails?.highlightLocation}
        </Typography>
        <Typography className={styles.heroImgCtn__nameCtn__region}>
          {tripDetails?.highlightLocationRegion}
        </Typography>
      </Box>
      <ShareTripButton
        isMobile
        variant="blue"
        className={styles.heroImgCtn__shareBtn}
        tripDetails={tripDetails}
      />
    </Box>
  );
}

export default TripDialogHero;
