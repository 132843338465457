import { useState } from "react";
import { Box, Button, Collapse, Typography } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import styles from "../trip.module.css";

const ExpenseListUI = ({
  expenseType,
  expensesArr = [],
  disableCollapse = false,
}) => {
  const expenseLimit = 3;
  const [isExpanded, setIsExpanded] = useState(false);

  function handleSeeMoreClick() {
    setIsExpanded(!isExpanded);
  }

  return (
    <Box className={styles.trip__expense__listBox}>
      <Typography className={styles.trip__expense__list__header}>
        {expenseType == "included_costs" ? "Cost includes:" : "Cost excludes:"}
      </Typography>
      <ul className={styles.trip__expense__list}>
        {expensesArr
          .slice(0, disableCollapse ? expensesArr.length : expenseLimit)
          .map((option, i) => (
            <li key={i}>
              <Typography className={styles.trip__expense__list__item}>
                {option}
              </Typography>
            </li>
          ))}
        {!disableCollapse && (
          <>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              {expensesArr.slice(expenseLimit).map((option, i) => (
                <li key={i}>
                  <Typography className={styles.trip__expense__list__item}>
                    {option}
                  </Typography>
                </li>
              ))}
            </Collapse>
            {expensesArr.length > expenseLimit && (
              <Button
                className={styles.trip__expense__seeMoreBtn}
                onClick={handleSeeMoreClick}
              >
                {isExpanded ? "See Less" : "See More"}
                <ExpandMoreIcon
                  className={[
                    styles.trip__expense__seeMoreBtn__icon,
                    isExpanded && styles.trip__expense__seeMoreBtn__icon__open,
                  ].join(" ")}
                  fontSize="small"
                />
              </Button>
            )}
          </>
        )}
      </ul>
    </Box>
  );
};

const TripExpenseList = ({ tripDetails, disableCollapse = false }) => {
  return (
    <Box className={styles.trip__expense__box}>
      {tripDetails.includedCosts && tripDetails.includedCosts?.length && (
        <ExpenseListUI
          expenseType={"included_costs"}
          expensesArr={tripDetails.includedCosts}
          disableCollapse={disableCollapse}
        />
      )}
      {tripDetails.excludedCosts && tripDetails.excludedCosts?.length && (
        <ExpenseListUI
          expenseType={"excluded_costs"}
          expensesArr={tripDetails.excludedCosts}
          disableCollapse={disableCollapse}
        />
      )}
    </Box>
  );
};

export default TripExpenseList;
