import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Image from "next/image";
import VerifiedIcon from "../../Host/verified.icon";
import styles from "../trip.module.css";

function HostBasicInfo({
  tripDetails,
  wrapperClassName = "",
  imageCtnClassName = "",
  hostNameClassName = "",
}) {
  return (
    <Stack direction="row" className={wrapperClassName}>
      <Stack>
        <Box
          className={[
            styles.tripHostCtn__profileImageCtn,
            tripDetails?.verifiedHost &&
              styles.tripHostCtn__profileImageCtn__verifiedBorder,
            imageCtnClassName,
          ].join(" ")}
        >
          <Image
            layout="fill"
            src={tripDetails?.hostImage + "?tx=c_fill,h_100,w_100"}
            alt={tripDetails?.hostName + " profile picture"}
          />
        </Box>
      </Stack>
      <Stack className={styles.tripHostCtn__hostNameCtn}>
        <Typography
          className={[styles.hostName, hostNameClassName].join(" ")}
          sx={{
            typography: {
              xs: "text-sm-semibold",
              md: "text-base-semibold",
            },
          }}
          component="h2"
        >
          {tripDetails?.hostName}{" "}
          {tripDetails?.verifiedHost && <VerifiedIcon />}
        </Typography>
        <Typography
          sx={{ typography: { xs: "text-xs", md: "text-base" } }}
          color="neutral-600.main"
        >
          Hosted <b>{tripDetails?.hostTripsCount}</b> trips so far
        </Typography>
      </Stack>
    </Stack>
  );
}

export default HostBasicInfo;
