import { useState } from "react";
import { Box } from "@mui/material";
import Slider from "react-slick";
import Image from "next/image";
import PlayIcon from "../../../public/images/icons/play_icon.svg";
import MediaViewer, { SlickVideo } from "../../ui/media-viewer/media.viewer";
import styles from "./trip.image.slider.module.css";

const mobileSettings = {
  dots: false,
  speed: 500,
  infinite: false,
  focusOnSelect: false,
  arrows: false,
  slidesToShow: 3.25,
  slidesToScroll: 3,
};

function SliderMedia({ media, onClick }) {
  const isVideo = media?.media_type === "video";

  return (
    <Box className={styles.imageCtn} onClick={onClick}>
      <Image
        layout="fill"
        src={isVideo ? media?.thumbnail_cdn_url : media?.cdn_url}
        alt={media?.caption}
      />
      {isVideo && (
        <Box className={styles.imageCtn__playIcon}>
          <Image src={PlayIcon} priority />
        </Box>
      )}
    </Box>
  );
}

function TripImageSlider({ className = "", tripDetails = {} }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currSlide, setCurrSlide] = useState(0);
  const media = tripDetails?.itineraryDetails?.experience_media ?? [];

  if (!media?.length) return null;

  const handleFSOpen =
    (idx = 0) =>
    () => {
      setIsFullScreen(true);
      setCurrSlide(idx);
    };

  function handleFSClose() {
    setIsFullScreen(false);
    setCurrSlide(0);
  }

  return (
    <>
      <Box
        className={[styles.container, className].join(" ")}
        sx={{
          padding: "16px 0 0 16px",
        }}
      >
        <Slider {...mobileSettings}>
          {media?.map((media, idx) => (
            <SliderMedia
              key={media?.id ?? idx}
              media={media}
              onClick={handleFSOpen(idx)}
            />
          ))}
        </Slider>
      </Box>
      <MediaViewer
        medias={media}
        open={isFullScreen}
        onClose={handleFSClose}
        activeIdx={currSlide}
        setActiveItemIdx={setCurrSlide}
        renderMobileItem={(media, idx) => {
          if (media?.media_type === "video") {
            return (
              <SlickVideo
                src={media?.cdn_url}
                controls
                play={idx === currSlide}
              />
            );
          }

          return <img src={media?.cdn_url} alt={media?.caption} />;
        }}
      />
    </>
  );
}

export default TripImageSlider;
