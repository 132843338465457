import { Box, Stack, Typography } from "@mui/material";
import TripFeatures from "./features";
import TripExpenseList from "./expense.list";
import ALink from "../../ui/link";
import styles from "../trip.module.css";

function ItineraryLocations({ itineraryLocations, mwebFontSize }) {
  return (
    <>
      {itineraryLocations?.length > 0 && (
        <Stack className={styles.tripItineraryLocations__nameLinksCtn__stack}>
          <Typography
            sx={{
              typography: {
                xs: mwebFontSize ? mwebFontSize : "text-xs",
                md: "text-base",
              },
            }}
            className={styles.tripItineraryLocations__nameLinksCtn__header}
          >
            Destinations:
          </Typography>
          {itineraryLocations.map((location, i) => (
            <ALink
              href={`/location/${location.urlSlug}`}
              aProps={{ target: "_blank" }}
              key={location.id}
            >
              <Typography
                sx={{
                  typography: {
                    xs: mwebFontSize ? mwebFontSize : "text-xs",
                    md: "text-base",
                  },
                  display: "flex",
                }}
                fontWeight="500"
                color="brand-600.main"
              >
                {location.name}
                {i !== itineraryLocations.length - 1 ? (
                  <span
                    className={
                      styles.tripItineraryLocations__nameLinksCtn__locationSpanSeparator
                    }
                  >
                    ,
                  </span>
                ) : null}
              </Typography>
            </ALink>
          ))}
        </Stack>
      )}
    </>
  );
}

const TripExpectation = ({
  tripDetails,
  ctnClassName = "",
  hideFeatures = false,
}) => {
  return (
    <>
      <Box
        className={[styles.tripDetail__dialog__expectCtn, ctnClassName].join(
          " "
        )}
      >
        <Typography
          component="h2"
          className={styles.tripDetail__dialog__expect}
        >
          What to expect?
        </Typography>
        <Box className={styles.tripDetail__dialog__expect__locTextCtn}>
          <ItineraryLocations
            itineraryLocations={tripDetails.itineraryLocations}
          />
          <Typography className={styles.tripDetail__dialog__expect__text}>
            {tripDetails?.highlightLocationDetail}
          </Typography>
          <Typography className={styles.tripDetail__dialog__expect__text}>
            {tripDetails?.expectation}
          </Typography>
        </Box>
        {!hideFeatures && (
          <Box mt={2} mb={2}>
            <TripFeatures tripDetails={tripDetails} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default TripExpectation;
