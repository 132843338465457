import React from "react";
import useShareClick from "../../../utils/use.share.click";
import Chip from "../../chip/chip";
import Image from "next/image";
import shareIcon from "../../../public/images/icons/share_white_filled.svg";
import styles from "./share.trip.button.module.css";

/**
 * @typedef {Object} ShareTripButtonProps
 * @property {string} className
 * @property {Object} tripDetails
 * @property {"green" | "blue"} variant
 * @property {boolean} isMobile
 * @param {ShareTripButtonProps} props
 */
function ShareTripButton({
  className = "",
  tripDetails,
  variant = "green",
  isMobile = false,
}) {
  const [copiedTooltipOpen, setCopiedTooltipOpen, handleShareClick] =
    useShareClick({
      isMobile,
      entity: "trip",
      entityId: tripDetails?.id,
      shareLink: `/${tripDetails?.urlPath}`,
    });

  return (
    <Chip
      className={[
        styles.chip,
        variant === "blue" && styles.chip__blue,
        className,
      ].join(" ")}
      label={
        (variant === "green" && "Share with friends") ||
        (variant === "blue" && "Share")
      }
      deleteIcon={<Image src={shareIcon} />}
      onDelete={handleShareClick}
      onClick={handleShareClick}
      labelSx={{
        paddingLeft: 0,
        paddingRight: "4px",
      }}
    />
  );
}

export default ShareTripButton;
