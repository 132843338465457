import React from "react";
import useIsMobile from "../../../utils/device.type.hook";
import { Box, Paper, Typography } from "@mui/material";
import Image from "next/image";
import styles from "./activity.module.css";

function Activity({ className = "", activity = {} }) {
  return (
    <Paper className={[styles.activityCtn, className].join(" ")} elevation={0}>
      <Image width={32} height={32} src={activity.img_cdn_url} priority />
      <Typography className={styles.activityCtn__description}>
        {activity.description_for_trip}
      </Typography>
    </Paper>
  );
}

function Activities({ className = "", tripDetails = {} }) {
  const isMobile = useIsMobile();
  const activities =
    tripDetails?.itineraryDetails?.activities_by_day_details ?? [];

  if (!activities?.length) {
    return null;
  }
  const isOddLength = activities?.length % 2 === 1;

  return (
    <Box className={[styles.activity__sectionCtn, className].join(" ")}>
      <Typography component="h2" className={styles.activity__section__header}>
        Activities
      </Typography>
      <Box className={styles.activityList}>
        {activities?.map((activity, idx) => (
          <Activity
            key={idx}
            className={
              isMobile && idx === 0 && isOddLength && styles.activityCtn__first
            }
            activity={activity}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Activities;
