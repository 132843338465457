import Image from "next/image";
import React from "react";
import UITooltip from "../../ui/tooltip";
import womenOnlyIcon from "../../../public/images/icons/women_only.svg";
import styles from "./info.module.css";
import Chip from "../../chip/chip";

function WomenOnlyIcon({ noTooltip }) {
  if (noTooltip) {
    return (
      <span
        className={[
          styles.womenOnlyIconCtn,
          styles.womenOnlyIconCtn__noPointer,
        ].join(" ")}
      >
        <Image src={womenOnlyIcon} alt="Women only trip" priority />
      </span>
    );
  }

  return (
    <UITooltip title="Women only trip">
      <span className={styles.womenOnlyIconCtn}>
        <Image src={womenOnlyIcon} alt="Women only trip" priority />
      </span>
    </UITooltip>
  );
}

function WomenOnlyChip({ noTooltip, className = "", disableIcon = false }) {
  return (
    <Chip
      className={[styles.womenOnlyInfoChip, className].join(" ")}
      label="Women Only"
      color="rose-400"
      icon={!disableIcon && <WomenOnlyIcon noTooltip={noTooltip} />}
      labelSx={{
        padding: "0 5px 0 3px",
        lineHeight: 1,
      }}
    />
  );
}

export default WomenOnlyChip;
export { WomenOnlyIcon };
