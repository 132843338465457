import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Typography, Box } from "@mui/material";
import Image from "next/image";
import { toast } from "react-toastify";
import styles from "../trip.module.css";
import { SOURCE } from "../../../utils/analytics";
import { captureEvent } from "../../google-analytics/tag";
import { EVENTNAME, SECTION } from "../../google-analytics/constants";
import { handlePostApiCall } from "../../../utils/api.call";
import CopyIcon from "../../../public/images/icons/copy.svg";
import ClickUITooltip from "../../ui/tooltip.click";
import { copyToClipboard } from "../../../utils/browser.api";

const DiscountCodeButton = ({ discountCode, handleClick, isMobile }) => {
  return (
    <Button
      className={styles.tripDetail__dialog__discountCodeBtn}
      variant="contained"
      disableElevation
      disableRipple
      onClick={handleClick}
    >
      {isMobile ? (
        <>
          {!discountCode && (
            <Typography
              variant="text-sm-semibold"
              component="span"
              color="secondary"
            >
              Get code
            </Typography>
          )}
        </>
      ) : (
        <Typography
          variant="text-sm-semibold"
          component="span"
          color="secondary"
        >
          {discountCode ? `Copy Code` : `Get Code`}
        </Typography>
      )}

      {discountCode ? <Image src={CopyIcon} priority /> : null}
    </Button>
  );
};

const DiscountCodeComponent = ({
  tripDetails,
  source,
  isLoggedIn,
  isMobile,
}) => {
  const dispatch = useDispatch();
  const [discountCode, setDiscountCode] = useState(tripDetails.discountCode);
  const [copiedTooltipOpen, setCopiedTooltipOpen] = useState(false);

  const ga_source =
    source == SOURCE.TRIP_DETAIL ? SECTION.TRIP_PAGE : SECTION.TRIP_DIALOG;
  const eventDetails = {
    id: tripDetails.id,
    name: tripDetails.name,
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    dispatch({ type: "OPEN_LOGIN_MODAL" });

    const ga_source =
      source == SOURCE.TRIP_DETAIL
        ? SECTION.TRIP_PAGE_DISCOUNT
        : SECTION.TRIP_DIALOG_DISCOUNT;
    captureEvent(EVENTNAME.LOGIN_CLICK, ga_source, eventDetails);
  };

  const handleCreateDiscountCode = async (e) => {
    e.preventDefault();
    const params = {
      trip_id: tripDetails.id,
      host_id: tripDetails.hostId,
    };
    const handleSuccessFn = (res) => {
      const createResponse = res.data;
      if (createResponse?.status == "success")
        setDiscountCode(createResponse?.discount_code?.name);
      else if (createResponse?.status == "failed") {
        toast(createResponse?.message, {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
        });
      }
    };

    await handlePostApiCall({
      url: "discount/create-code",
      params,
      handleSuccessFn,
    });

    captureEvent(EVENTNAME.CREATE_DISCOUNT_CODE_CLICK, ga_source, eventDetails);
  };

  const handleCopyDiscountCode = async (e) => {
    e.preventDefault();
    const successFn = () => setCopiedTooltipOpen(true);
    copyToClipboard(discountCode, successFn);

    captureEvent(EVENTNAME.COPY_DISCOUNT_CODE_CLICK, ga_source, eventDetails);
  };

  if (
    tripDetails.isBooked ||
    // No descriptions on group discounts, hide
    (!tripDetails.discountPrimaryDescription &&
      !tripDetails.discountSecondaryDescription)
  )
    return <></>;

  if (!isLoggedIn) {
    return (
      <Box marginTop="8px">
        <Button
          className={styles.tripDetail__dialog__discountCodeBox}
          variant="contained"
          disableElevation
          disableRipple
          onClick={handleLoginClick}
        >
          <Typography
            variant="text-base-semibold"
            component="div"
            color="secondary"
          >
            {isMobile
              ? `Login and get ${tripDetails.costCurrency}${tripDetails.discountValue} off`
              : `Login and get ${tripDetails.costCurrency}${tripDetails.discountValue} off for this trip`}
          </Typography>
        </Button>
      </Box>
    );
  }

  return (
    <Box
      className={styles.tripDetail__dialog__discountCodeCtn}
      onClick={(e) => e.preventDefault()}
    >
      <Box className={styles.tripDetail__dialog__discountCodeCtn__textCtn}>
        <Typography
          className={styles.tripDetail__dialog__discountCodeCtn__primaryDesc}
        >
          {discountCode
            ? isMobile
              ? `Offer code: ${discountCode}`
              : `Woohoo! Save ${tripDetails.costCurrency}${tripDetails.discountValue} with code: ${discountCode}`
            : tripDetails.discountPrimaryDescription}
        </Typography>
        <Typography
          className={styles.tripDetail__dialog__discountCodeCtn__secondaryDesc}
        >
          {discountCode
            ? isMobile
              ? "Share this code with the host"
              : "Share this unique code with the host to get discount on this trip"
            : tripDetails.discountSecondaryDescription}
        </Typography>
      </Box>
      {!tripDetails.disableDiscountCode ? (
        discountCode ? (
          <ClickUITooltip
            title="Copied!"
            open={copiedTooltipOpen}
            handleClose={() => setCopiedTooltipOpen(false)}
          >
            <span>
              <DiscountCodeButton
                discountCode={discountCode}
                handleClick={handleCopyDiscountCode}
                isMobile={isMobile}
              />
            </span>
          </ClickUITooltip>
        ) : (
          <DiscountCodeButton
            discountCode={discountCode}
            handleClick={handleCreateDiscountCode}
            isMobile={isMobile}
          />
        )
      ) : null}
    </Box>
  );
};

export default DiscountCodeComponent;
