import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Close as CloseIcon,
  Remove as RemoveIcon,
  Add as AddIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import TripDiscount from "../trip.discount";
import { getNumberInCommaFormat } from "../../../utils/string";
import HostBasicInfo from "./host.basic.info";
import ContactHostButton from "./host.contact.button";
import { toast } from "react-toastify";
import { handlePostApiCall } from "../../../utils/api.call";
import { captureEvent } from "../../google-analytics/tag";
import { EVENTNAME, SECTION } from "../../google-analytics/constants";
import { AUTH_CONTEXT, AUTH_TYPE } from "../../auth/login";
import TripExpectation from "./expectation";
import TripExpenseList from "./expense.list";
import styles from "./booking.button.module.css";

function BookingButton({ tripDetails, source, buttonClassName = "" }) {
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);
  const [travellerCount, setTravellerCount] = useState(1);
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [showBookingSuccess, setShowBookingSuccess] = useState(false);

  function handleDialogOpen() {
    setIsDialogOpen(true);
    captureEvent(EVENTNAME.BOOK_TRIP_CLICK, source);
  }

  function handleDataReset() {
    setTravellerCount(1);
    setShowBookingSuccess(false);
  }

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  function handleTravellerEvent() {
    captureEvent(EVENTNAME.BOOK_TRIP_PAX, source);
  }

  function handleTravellerIncrement() {
    setTravellerCount((prevCount) => prevCount + 1);
    handleTravellerEvent();
  }

  function handleTravellerDecrement() {
    setTravellerCount((prevCount) => {
      if (prevCount <= 1) {
        return prevCount;
      }
      return prevCount - 1;
    });
    handleTravellerEvent();
  }

  function handleBookingSubmit() {
    if (tripDetails?.isBooked) return;

    if (!isLoggedIn) {
      let payload = {
        authType: AUTH_TYPE.REGISTER,
        authContext: AUTH_CONTEXT.BOOKING,
        hostDetails: tripDetails?.host,
      };
      if (tripDetails?.discountValue !== 0) {
        payload = {
          ...payload,
          bookingCost: getNumberInCommaFormat(
            tripDetails?.discountValue * travellerCount
          ),
          bookingCurrency: tripDetails?.costCurrency,
        };
      }

      dispatch({
        type: "OPEN_LOGIN_MODAL",
        payload: payload,
      });
      captureEvent(EVENTNAME.LOGIN_CLICK, SECTION.BOOKING_DIALOG, {
        id: tripDetails?.id,
        name: tripDetails?.name,
      });
      return;
    }

    captureEvent(EVENTNAME.BOOKING_REQUEST, source);
    setIsBookingLoading(true);

    const bookingParams = {
      trip_id: tripDetails?.id,
      host_id: tripDetails?.hostId,
      number_of_people: travellerCount,
    };

    function handleSuccessFn(res) {
      if (res?.data?.detail?.status === "failed") {
        toast(res?.data?.detail?.message, {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
          autoClose: 3000,
        });
        return;
      }

      setShowBookingSuccess(true);
    }

    handlePostApiCall({
      url: "messenger/message-booking",
      params: bookingParams,
      handleSuccessFn,
    }).finally(() => {
      setIsBookingLoading(false);
    });
  }

  return (
    <>
      <Button
        variant="contained"
        className={[styles.bookingBtn, buttonClassName].join(" ")}
        onClick={handleDialogOpen}
        disabled={
          tripDetails?.isBooked || new Date() > new Date(tripDetails?.startDate)
        }
        disableElevation
      >
        Book A Seat
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          className: [
            styles.booking__dialogCtn,
            tripDetails?.discountValue && styles.booking__dialogCtn__discount,
            showBookingSuccess && styles.booking__dialogCtn__success,
            showBookingSuccess && styles.booking__dialogCtn__discount__success,
          ],
        }}
        // Reset data on dialog close animation end
        TransitionProps={{
          onExited: handleDataReset,
        }}
      >
        <Box className={styles.booking__dialogHeader}>
          <Typography className={styles.booking__dialogHeader__text}>
            Request Booking
          </Typography>
          <IconButton onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={styles.booking__dialogLayoutCtn}>
          <Box
            className={[
              styles.booking__dialogLayoutCtn__bookingCtn,
              showBookingSuccess &&
                styles.booking__dialogLayoutCtn__bookingCtn__success,
            ].join(" ")}
          >
            {showBookingSuccess ? (
              <Box className={styles.booking__dialogBookingCtn__doneCtn}>
                <Box className={styles.booking__dialogBookingCtn__checkIconCtn}>
                  <CheckIcon color="secondary" fontSize="inherit" />
                </Box>
                <Typography
                  className={styles.booking__dialogBookingCtn__doneText}
                >
                  Your request for booking has been sent to{" "}
                  {tripDetails?.hostName}. <br /> <br /> You will receive
                  notification on email as soon as host responds to your booking
                  request. You can also contact host using Cohyk Chat.
                </Typography>
                <Button
                  variant="contained"
                  className={styles.booking__dialogBookingCtn__doneBtn}
                  onClick={handleDialogClose}
                  disableElevation
                >
                  Okay!
                </Button>
              </Box>
            ) : (
              <>
                {tripDetails?.discountPercentage !== 0 && (
                  <Box className={styles.booking__dialogDiscountCtn}>
                    <Typography
                      className={styles.booking__dialogDiscountCtn__text}
                    >
                      Limited time offer
                    </Typography>
                    <TripDiscount tripDetails={tripDetails} />
                  </Box>
                )}
                <Box className={styles.booking__dialogBookingCtn}>
                  <Box className={styles.booking__dialogCalculatorCtn}>
                    <Box
                      className={styles.booking__dialogCalculatorCtn__itemsCtn}
                    >
                      <Box
                        className={styles.booking__dialogCalculatorCtn__itemCtn}
                      >
                        <Typography
                          className={
                            styles.booking__dialogCalculatorCtn__leftColText
                          }
                        >
                          Travellers
                        </Typography>
                        <Box
                          className={
                            styles.booking__dialogCalculatorCtn__travellerCount
                          }
                        >
                          <IconButton
                            className={[
                              styles.booking__dialogCalculatorCtn__travellerCount__btn,
                              travellerCount < 2 &&
                                styles.booking__dialogCalculatorCtn__travellerCount__removeBtn,
                            ].join(" ")}
                            variant="contained"
                            onClick={handleTravellerDecrement}
                          >
                            <RemoveIcon
                              className={
                                styles.booking__dialogCalculatorCtn__travellerCount__icon
                              }
                            />
                          </IconButton>
                          <Typography
                            className={[
                              styles.booking__dialogCalculatorCtn__travellerCount__text,
                              styles.booking__dialogCalculatorCtn__rightColText,
                            ].join(" ")}
                          >
                            {travellerCount}
                          </Typography>
                          <IconButton
                            className={[
                              styles.booking__dialogCalculatorCtn__travellerCount__btn,
                            ].join(" ")}
                            variant="contained"
                            onClick={handleTravellerIncrement}
                          >
                            <AddIcon
                              className={
                                styles.booking__dialogCalculatorCtn__travellerCount__icon
                              }
                            />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box
                        className={styles.booking__dialogCalculatorCtn__itemCtn}
                      >
                        <Typography
                          className={
                            styles.booking__dialogCalculatorCtn__leftColText
                          }
                        >
                          Trip cost
                        </Typography>
                        <Typography
                          className={
                            styles.booking__dialogCalculatorCtn__rightColText
                          }
                        >
                          {`${
                            tripDetails?.costCurrency
                          }${getNumberInCommaFormat(
                            tripDetails?.costPerPersonInNumber * travellerCount
                          )}`}
                        </Typography>
                      </Box>
                      {tripDetails?.discountValue !== 0 && (
                        <Box
                          className={
                            styles.booking__dialogCalculatorCtn__itemCtn
                          }
                        >
                          <Typography
                            className={
                              styles.booking__dialogCalculatorCtn__leftColText
                            }
                          >
                            Discount
                          </Typography>
                          <Typography
                            className={[
                              styles.booking__dialogCalculatorCtn__rightColText,
                              styles.booking__dialogCalculatorCtn__discountValue,
                            ].join(" ")}
                          >
                            {`-${
                              tripDetails?.costCurrency
                            }${getNumberInCommaFormat(
                              tripDetails?.discountValue * travellerCount
                            )}`}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        className={
                          styles.booking__dialogCalculatorCtn__dottedDiv
                        }
                      />
                      <Box
                        className={styles.booking__dialogCalculatorCtn__itemCtn}
                      >
                        <Typography
                          className={
                            styles.booking__dialogCalculatorCtn__leftColText
                          }
                        >
                          Total
                        </Typography>
                        <Typography
                          className={[
                            styles.booking__dialogCalculatorCtn__rightColText,
                            styles.booking__dialogCalculatorCtn__totalText,
                          ].join(" ")}
                        >
                          {`${
                            tripDetails?.costCurrency
                          }${getNumberInCommaFormat(
                            (tripDetails?.costPerPersonInNumber -
                              tripDetails?.discountValue) *
                              travellerCount
                          )}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Button
                    className={styles.booking__dialogBookingCtn__bookingBtn}
                    variant="contained"
                    onClick={handleBookingSubmit}
                    disableElevation
                  >
                    {isLoggedIn ? (
                      isBookingLoading ? (
                        <CircularProgress color="secondary" size={28} />
                      ) : (
                        "Request Booking"
                      )
                    ) : (
                      "Login and Request Booking"
                    )}
                  </Button>
                </Box>
                <Box className={styles.booking__dialogBookingCtn__contactCtn}>
                  <Typography
                    className={
                      styles.booking__dialogBookingCtn__contactCtn__header
                    }
                  >
                    Contact the Host
                  </Typography>
                  <Box
                    className={
                      styles.booking__dialogBookingCtn__contactInnerCtn
                    }
                  >
                    <HostBasicInfo
                      tripDetails={tripDetails}
                      imageCtnClassName={
                        styles.booking__dialogBookingCtn__contactHostImgCtn
                      }
                      hostNameClassName={
                        styles.booking__dialogBookingCtn__contactHostName
                      }
                    />
                    <ContactHostButton
                      tripDetails={tripDetails}
                      source={source}
                      buttonStyleClass={
                        styles.booking__dialogBookingCtn__contactBtn
                      }
                      iconOnly
                      iconVariant="white"
                      handleContactHostClick={handleDialogClose}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          {!showBookingSuccess && (
            <Box className={styles.booking__dialogLayoutCtn__detailsCtn}>
              <TripExpectation
                ctnClassName={
                  styles.booking__dialogLayoutCtn__detailsCtn__expectation
                }
                tripDetails={tripDetails}
                hideFeatures
              />
              <TripExpenseList tripDetails={tripDetails} disableCollapse />
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
}

export default BookingButton;
