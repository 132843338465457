import React, { useState, useEffect, forwardRef } from "react";
import { Dialog, Stack, Slide } from "@mui/material";
import { parseTripObject } from "./trip";
import { handleGetApiCall } from "../../utils/api.call";
import MobileTripContentUI from "./mobile.trip";
import BackButton from "../navbar/components/back.button";
import { ENTITY, EVENT_NAME, sendEvent } from "../../utils/analytics";
import { EVENTNAME, SECTION } from "../google-analytics/constants";
import { captureEvent } from "../google-analytics/tag";
import TripDialogHero from "./components/trip.dialog.hero";
import styles from "./trip.detail.dialog.module.css";

const TRIP_DETAIL_URL = "trip/path/";

const dialogStyle = {
  borderRadius: "0px",
  width: "100vw",
  overscrollBehavior: "contain",
  overflowX: "hidden",
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TripDetails({
  noDialog = false,
  dialogOpen,
  setDialogOpen,
  hostId,
  tripHashId,
  useTripDetailsProp = false,
  tripDetailsProp = {},
  gaSource,
}) {
  const [tripDetails, setTripDetails] = useState(tripDetailsProp);
  const [tripLoaded, setTripLoaded] = useState(
    useTripDetailsProp ? true : false
  );

  const handleClose = () => setDialogOpen(false);

  useEffect(() => {
    if (dialogOpen === false || useTripDetailsProp) return;

    const handleSuccess = (res) => {
      setTripDetails(parseTripObject(res.data));
      setTripLoaded(true);
    };
    const handleFailure = () => {
      setTimeout(() => {
        handleClose();
      }, 800);
    };
    handleGetApiCall(
      TRIP_DETAIL_URL + tripHashId,
      {},
      handleSuccess,
      handleFailure
    );

    // Trip detail dialog is reused between trip dialog and trip page on mobile
    // noDialog is true when it is being called on trip page in a mobile view
    // Trip dialog needs to fire it's view analytics when in an actual dialog view,
    // which is when noDialog is falsy
    if (!noDialog && dialogOpen) {
      sendEvent(
        hostId, //tripId,
        ENTITY.HOST,
        null,
        EVENT_NAME.TRIP_DIALOG_PAGE_IMPRESSION
      );
    }
  }, [dialogOpen]);

  useEffect(() => {
    if (!noDialog && dialogOpen && tripDetails.id && gaSource) {
      captureEvent(EVENTNAME.TRIP_DIALOG_OPEN, gaSource, {
        entity_id: tripDetails.id,
        entity_type: "trip",
        name: tripDetails.highlightLocation,
      });
    }
  }, [dialogOpen, tripDetails]);

  if (noDialog) {
    return (
      <MobileTripContentUI
        tripLoaded={tripLoaded}
        tripDetails={tripDetails}
        source={SECTION.TRIP_PAGE}
      />
    );
  }

  return (
    <Dialog
      onClose={handleClose}
      open={dialogOpen}
      fullScreen
      fullWidth
      maxWidth="sm"
      TransitionComponent={Transition}
      PaperProps={{ style: dialogStyle }}
    >
      <Stack className={styles.tripDetailsDialogCtn__stack}>
        <TripDialogHero tripDetails={tripDetails} />
        <BackButton onBackClick={handleClose} noNavbar />
        <MobileTripContentUI
          tripLoaded={tripLoaded}
          tripDetails={tripDetails}
          source={SECTION.TRIP_DIALOG}
        />
      </Stack>
    </Dialog>
  );
}
