import React, { forwardRef } from "react";
import { Button, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import ALink from "./link";
import styles from "./chevron.link.button.module.css";

function ChevronLinkButtonBase({ text, fontStyle, direction }) {
  return (
    <>
        <Typography
          variant="text-base-semibold"
          color="brand-600.main"
          sx={fontStyle}
        >
          {text}
        </Typography>
        <ChevronRight
          className={[
            direction === "down" && styles.chevron_link_button__chevron_down,
          ].join(" ")}
          color="brand-500"
        />
      </>
  )
}

const ChevronLinkButton = forwardRef(
  (
    {
      text,
      chevronDir,
      href,
      fontStyle,
      onClick,
      outlinedButton = false,
      buttonStyle,
      noLinkWrap = false,
      openNewTab = false,
      className = ""
    },
    ref
  ) => {
    if (noLinkWrap) {
      return (
        <Button
          className={[
            styles.chevron_link_button,
            outlinedButton && styles.chevron_link_button_outlined,
            className
          ].join(" ")}
          onClick={onClick}
          disableElevation
          ref={ref}
          sx={buttonStyle}
        >
          <ChevronLinkButtonBase
            text={text}
            fontStyle={fontStyle}
            direction={chevronDir}
            ref={ref}
          />
        </Button>
      );
    }

    return (
      <ALink
        href={href}
        aProps={{
          className: [
            styles.chevron_link_button,
            outlinedButton && styles.chevron_link_button_outlined,
            className
          ].join(" "),
          target: openNewTab ? "_blank" : "_self",
          ref: ref,
        }}
      >
        <ChevronLinkButtonBase
          text={text}
          fontStyle={fontStyle}
          direction={chevronDir}
          outlinedButton={outlinedButton}
          buttonStyle={buttonStyle}
          onClick={onClick}
          ref={ref}
        />
      </ALink>
    );
  }
);

export default ChevronLinkButton;
