import React from "react";
import PropTypes from "prop-types";
import LocationClass from "./location";
import { Box, Stack, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./image.carousel.module.css";

function ImageCarousel({
  locationDetail,
  className = "",
  showIndicators = true,
  displayName = false,
}) {
  if (!locationDetail) return <></>;

  return (
    <Box className={styles.imageCarousel__ctn}>
      {displayName && (
        <Box className={styles.imageCarousel__nameCtn}>
          <Typography className={styles.imageCarousel__name}>
            {locationDetail?.name}
          </Typography>
          <Typography className={styles.imageCarousel__region}>
            {locationDetail?.region}
          </Typography>
        </Box>
      )}
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={showIndicators}
        infiniteLoop
      >
        {locationDetail?.photos?.length ? (
          locationDetail?.photos?.map((item) => (
            <Stack
              key={item.id}
              className={[styles.imageCarousel__imageContainer, className].join(
                " "
              )}
            >
              <img
                src={item.cdn_url + "?tx=c_fill,h_780,w_auto"}
                alt={
                  "Image " +
                  (item.id + 1) +
                  " for location " +
                  locationDetail.name
                }
              />
            </Stack>
          ))
        ) : (
          <Stack
            className={[styles.imageCarousel__imageContainer, className].join(
              " "
            )}
          >
            <img
              src={
                locationDetail?.regionType?.bg_img_cdn_url +
                "?tx=c_fill,h_780,w_auto"
              }
              alt={"Image for location " + locationDetail.name}
            />
          </Stack>
        )}
      </Carousel>
    </Box>
  );
}

ImageCarousel.propTypes = {
  locationDetail: PropTypes.instanceOf(LocationClass).isRequired,
};

export default ImageCarousel;
