import React from "react";
import { useSelector } from "react-redux";
import { Stack, Paper, Box } from "@mui/material";
import Image from "next/image";
import InstagramIcon from "../../../public/images/insta.svg";
import Chip from "../../chip/chip";
import styles from "../trip.module.css";
import ALink from "../../ui/link";
import useIsMobile from "../../../utils/device.type.hook";
import DiscountCodeComponent from "./discount.code";
import BookingButton from "./booking.button";
import HostBasicInfo from "./host.basic.info";
import ContactHostButton from "./host.contact.button";

const TripHost = ({
  tripDetails,
  newTab,
  source,
  hideBookingAndContact = false,
}) => {
  const isMobile = useIsMobile();
  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);

  return (
    <Paper className={styles.host_container}>
      <ALink
        href={tripDetails.hostURLPath}
        aProps={{
          target: newTab ? "_blank" : isMobile ? "_self" : "_blank",
          className: styles.tripHostCtn__hostPageLink,
        }}
      />
      <HostBasicInfo tripDetails={tripDetails} />
      <Stack direction="row" spacing={1}>
        <Chip
          icon={<Image src={InstagramIcon} priority />}
          label={tripDetails.hostFollowersCount + " followers"}
          color="neutral-100"
          sx={{
            fontSize: "12px !important",
          }}
        />
      </Stack>
      {isLoggedIn ? (
        <>
          {tripDetails.discountPercentage ? (
            <DiscountCodeComponent
              tripDetails={tripDetails}
              source={source}
              isLoggedIn={isLoggedIn}
              isMobile={isMobile}
            />
          ) : null}
          {!hideBookingAndContact && (
            <Box className={styles.tripHostCtn__hostContactCtn}>
              <BookingButton tripDetails={tripDetails} source={source} />
              <ContactHostButton
                tripDetails={tripDetails}
                source={source}
                buttonStyleClass={styles.tripDetail__dialog__contactBtn}
                buttonTextTheme={"primary"}
              />
            </Box>
          )}
        </>
      ) : (
        !hideBookingAndContact && (
          <Box className={styles.tripHostCtn__hostContactCtn}>
            <BookingButton tripDetails={tripDetails} source={source} />
            <ContactHostButton
              tripDetails={tripDetails}
              source={source}
              buttonStyleClass={styles.tripDetail__dialog__contactBtn}
              buttonTextTheme={"primary"}
            />
          </Box>
        )
      )}
    </Paper>
  );
};

export default TripHost;
