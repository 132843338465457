import Chip from "../chip/chip";

const TripDiscount = ({
  className = "",
  discount,
  labelSx,
  sx,
  tripDetails,
  usePercentage = false,
}) => {
  if (!tripDetails.discountPercentage || tripDetails.isBooked) {
    return null;
  }

  return (
    <Chip
      className={className}
      label={
        usePercentage
          ? `${tripDetails?.discountPercentage}% off`
          : `${tripDetails.costCurrency}${tripDetails.discountValue} off`
      }
      labelSx={{
        fontWeight: "700",
        color: "#fff",
        ...labelSx,
      }}
      color="amber-400"
      sx={{
        padding: "4px !important",
        ...sx,
      }}
    />
  );
};

export default TripDiscount;
