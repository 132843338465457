import React from "react";
import { Box, Typography } from "@mui/material";
const TripHighlightLocationDetail = ({ tripDetails, styles }) => {
  return (
    <Box m={"0px 0px 16px 0px"}>
      <Typography
        sx={{ typography: { xs: "text-xs", md: "text-base" } }}
        style={styles}
        component="div"
        color="#475569"
      >
        {tripDetails?.highlightLocationDetail}
      </Typography>
    </Box>
  );
};

export default TripHighlightLocationDetail;
