import React from "react";
import Chip from "../../chip/chip";
import { Box } from "@mui/material";
import Image from "next/image";
import StarGreen from "../../../public/images/icons/star_green.svg";
import styles from "./rating.pill.module.css";

function RatingPill({ rating = null }) {
  if (!rating) return <></>;

  return (
    <Chip
      className={styles.ratingPill}
      icon={
        <Box className={styles.ratingPill__star}>
          <Image layout="fill" src={StarGreen} />
        </Box>
      }
      label={rating}
    />
  );
}

export default RatingPill;
