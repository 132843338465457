import React, { useState, useRef } from "react";
import { Dialog, Typography, Box, Stack } from "@mui/material";
import styles from "../trip.module.css";
import Slider from "react-slick";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import useIsMobile from "../../../utils/device.type.hook";
import { captureEvent } from "../../google-analytics/tag";
import { EVENTNAME, SECTION } from "../../google-analytics/constants";
import {
  ENTITY,
  EVENT_NAME,
  sendEvent,
  SOURCE,
} from "../../../utils/analytics";
import ShareButton from "../../ui/share.button";
import TripExpenseList from "./expense.list";
import dynamic from "next/dynamic";
const Map = dynamic(() => import("../../map"), { ssr: false });

const ArrowLeft = (props) =>
  props?.onClick && (
    <Box {...props} className={styles.slider_prevBtn}>
      <KeyboardArrowLeftIcon />
    </Box>
  );
const ArrowRight = (props) =>
  props?.onClick && (
    <Box {...props} className={styles.slider_nextBtn}>
      <KeyboardArrowRightIcon />
    </Box>
  );

const settings = {
  dots: false,
  speed: 500,
  infinite: false,
  slidesToShow: 2.2,
  slidesToScroll: 1,
  centerPadding: "5px",
  focusOnSelect: false,
};

const mobileSettings = {
  ...settings,
  arrows: false,
  slidesToShow: 1.6,
};

const desktopSettings = {
  ...settings,
  slidesToShow: 3.5,
  arrows: true,
  draggable: false,
  prevArrow: <ArrowLeft />,
  nextArrow: <ArrowRight />,
};

function ItineraryOverlay({ image, onClick, isStaticMap = false }) {
  if (!image?.caption_header && !image?.caption && !isStaticMap) {
    return <></>;
  }

  return (
    <Box
      className={[
        styles.itinerary_imagesSlider__imgCtn__overlay,
        isStaticMap &&
          styles.itinerary_imagesSlider__imgCtn__overlay__staticMap,
      ].join(" ")}
      onClick={onClick}
    >
      <Stack
        className={styles.itinerary_imagesSlider__imgCtn__overlay__textCtn}
      >
        {image?.caption_header && (
          <Typography
            className={styles.itinerary_imagesSlider__imgCtn__overlay__header}
          >
            {image?.caption_header}
          </Typography>
        )}
        {image?.caption && (
          <Typography
            className={styles.itinerary_imagesSlider__imgCtn__overlay__caption}
          >
            {image?.caption}
          </Typography>
        )}
        {isStaticMap && (
          <Typography
            className={
              styles.itinerary_imagesSlider__imgCtn__overlay__caption__staticMap
            }
          >
            Journey Map
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

const TripIternary = ({ tripDetails }) => {
  const isMobile = useIsMobile();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const fsSliderRef = useRef(null);
  const [isMapOpen, setIsMapOpen] = useState(false);

  function handleSlickGoTo() {
    if (fsSliderRef.current) {
      fsSliderRef.current.slickGoTo(currentSlide);
    }
  }

  function handleFSOpen(idxOpened = 0) {
    setIsFullScreen(true);
    setCurrentSlide(idxOpened);

    const eventData = {
      id: tripDetails.id,
      name: tripDetails.name,
    };
    let gaSource = SECTION.TRIP_PAGE;
    let eventSource = SOURCE.TRIP_DETAIL;

    if (isMobile) {
      gaSource = SECTION.TRIP_DIALOG;
      eventSource = SOURCE.TRIP_DIALOG;
    }

    captureEvent(EVENTNAME.ITINERARY_CLICK, gaSource, eventData);
    sendEvent(
      tripDetails.itineraryDetails?.id,
      ENTITY.ITINERARY,
      eventSource,
      EVENT_NAME.ITINERARY_CLICKS
    );
  }

  function handleFSClose() {
    setIsFullScreen(false);
    setCurrentSlide(0);
  }

  function handleMapOpen() {
    setIsMapOpen(true);
  }

  function handleMapClose() {
    setIsMapOpen(false);
  }

  return tripDetails.itinerary.length > 0 || tripDetails.staticMap ? (
    <Box>
      <Box className={styles.tripDetail__itineraryCtn}>
        <Box className={styles.tripDetail__itinerary_headerCtn}>
          <Typography
            component="h2"
            className={styles.tripDetail__itinerary_headerText}
          >
            Itinerary
          </Typography>
          {!isMobile && (
            <ShareButton
              entity="trip"
              entityId={tripDetails.id}
              hideBorder
              buttonText="Share Itinerary"
            />
          )}
        </Box>
        {isMobile && (
          <Slider {...mobileSettings} className={styles.itinerary_imagesSlider}>
            {tripDetails.staticMap ? (
              <Box className={styles.itinerary_imagesSlider__imgCtn}>
                <img
                  className={styles.itinerary_imagesSlider__imgCtn__img}
                  src={tripDetails.staticMap?.cdn_url}
                  onClick={handleMapOpen}
                  alt={
                    "Image of the map for the itinerary for the trip to " +
                    tripDetails.highlightLocation +
                    " with " +
                    tripDetails.hostName
                  }
                />
                <ItineraryOverlay isStaticMap onClick={handleMapOpen} />
              </Box>
            ) : null}
            {tripDetails.itinerary.map((image, index) => (
              <Box
                key={index}
                className={styles.itinerary_imagesSlider__imgCtn}
              >
                <img
                  className={styles.itinerary_imagesSlider__imgCtn__img}
                  src={image.cdn_url}
                  onClick={() => handleFSOpen(index)}
                  alt={
                    "Image " +
                    (index + 1) +
                    " of the itinerary for the trip to " +
                    tripDetails.highlightLocation +
                    " with " +
                    tripDetails.hostName
                  }
                />
                <ItineraryOverlay
                  image={image}
                  onClick={() => handleFSOpen(index)}
                />
              </Box>
            ))}
          </Slider>
        )}

        {!isMobile && (
          <Slider
            {...desktopSettings}
            className={styles.itinerary_imagesSlider}
          >
            {tripDetails.staticMap ? (
              <Box className={styles.itinerary_imagesSlider__imgCtn}>
                <img
                  className={styles.itinerary_imagesSlider__imgCtn__img}
                  src={tripDetails.staticMap?.cdn_url}
                  onClick={handleMapOpen}
                  alt={
                    "Image of the map for the itinerary for the trip to " +
                    tripDetails.highlightLocation +
                    " with " +
                    tripDetails.hostName
                  }
                />
                <ItineraryOverlay isStaticMap onClick={handleMapOpen} />
              </Box>
            ) : null}
            {tripDetails.itinerary.map((image, index) => (
              <Box
                key={index}
                className={styles.itinerary_imagesSlider__imgCtn}
              >
                <img
                  className={styles.itinerary_imagesSlider__imgCtn__img}
                  src={image.cdn_url}
                  onClick={() => handleFSOpen(index)}
                  alt={
                    "Image " +
                    (index + 1) +
                    " of the itinerary for the trip to " +
                    tripDetails.highlightLocation +
                    " with " +
                    tripDetails.hostName
                  }
                />
                <ItineraryOverlay
                  image={image}
                  onClick={() => handleFSOpen(index)}
                />
              </Box>
            ))}
          </Slider>
        )}
        <TripExpenseList tripDetails={tripDetails} />
      </Box>
      <Dialog
        open={isFullScreen}
        onClose={handleFSClose}
        TransitionProps={{
          onEnter: handleSlickGoTo,
        }}
      >
        <Slider
          {...{
            ...desktopSettings,
            slidesToShow: 1,
          }}
          ref={fsSliderRef}
        >
          {tripDetails.itinerary.map((image, index) => (
            <Box key={index} sx={{ display: "block !important" }}>
              <img
                src={image.cdn_url}
                alt={
                  "Image " +
                  (index + 1) +
                  " of the itinerary for the trip to " +
                  tripDetails.highlightLocation +
                  " with " +
                  tripDetails.hostName
                }
                style={{
                  maxHeight: "calc(100vh - 64px)",
                  objectFit: "contain",
                }}
              />
            </Box>
          ))}
        </Slider>
      </Dialog>
      <Dialog open={isMapOpen} onClose={handleMapClose}>
        <Map
          className={styles.itinerary_imagesSlider__mapCtn}
          locations={tripDetails?.itineraryLocations?.map((location) => ({
            id: location?.id,
            name: location?.name,
            longitude: location?.longitude,
            latitude: location?.latitude,
          }))}
        />
      </Dialog>
    </Box>
  ) : (
    <></>
  );
};

export default TripIternary;
