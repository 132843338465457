import React from "react";
import { Grid, Box } from "@mui/material";
import InfoBox from "../trip.infobox";
const TripFeatures = ({ tripDetails }) => {
  return (
    <Grid container spacing={2}>
      {tripDetails.features?.map((feature, index) => {
        return (
          <Grid key={index} item xs={6} md={3}>
            <Box>
              <InfoBox feature={feature} />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TripFeatures;
