import React from "react";
import { Box } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import Chip from "../../chip/chip";
import styles from "./info.module.css";
import WomenOnlyChip from "./women.only.chip";
import TripSeats from "./trip.seats";

const TripInfo = ({ tripDetails }) => {
  return (
    <Box className={styles.tripInfoCtn}>
      {tripDetails.womenOnly && (
        <Box className={styles.tripInfoCtn__womenOnlyInfoChip}>
          <WomenOnlyChip />
        </Box>
      )}
      <Chip
        label={`From ${tripDetails?.startingLocation}`}
        color="neutral-100"
      />
      <Chip
        label={`To ${tripDetails?.highlightLocation}`}
        color="neutral-100"
      />
      <Chip label={`${tripDetails?.startDate_str}`} color="neutral-100" />
      <Chip label={`${tripDetails?.durationDetail}`} color="neutral-100" />
      <Chip
        label={`${tripDetails?.tripType}`}
        icon={
          <img
            className={styles.tripInfoCtn__tripTypeImage}
            src={`${tripDetails?.tripTypeImage}`}
          />
        }
        color="neutral-100"
      />
      <Chip
        icon={
          <PeopleIcon
            style={{
              // marginLeft: "4px",
              marginRight: "2px",
              marginTop: "-2px",
              fontSize: "18px",
            }}
          />
        }
        label={`${tripDetails?.groupSize} persons`}
        color="neutral-100"
      />
      <Chip label={`By ${tripDetails?.transports_str}`} color="neutral-100" />
      <TripSeats
        className={styles.tripInfoCtn__availableSeats}
        trip={tripDetails}
      />
    </Box>
  );
};

export default TripInfo;
