import React from "react";
import { Typography } from "@mui/material";

const TripCost = ({ tripDetails }) => {
  return (
    <>
      <Typography
        component="div"
        color="primary"
        fontSize={32}
        fontWeight={600}
        display="inline"
      >
        {!tripDetails.isBooked && tripDetails.discountedCostPerPerson
          ? `@ ${tripDetails?.costCurrency}${tripDetails?.discountedCostPerPerson}`
          : `@ ${tripDetails?.costCurrency}${tripDetails?.costPerPerson}`}
      </Typography>
      {!tripDetails.isBooked && tripDetails.discountPercentage ? (
        <Typography
          component="div"
          variant="text-xl-semibold"
          color="neutral-400.main"
          display="inline"
          sx={{
            marginLeft: "8px",
            textDecoration: "line-through",
          }}
        >
          {" "}
          {`${tripDetails?.costCurrency}${tripDetails?.costPerPerson}`}
        </Typography>
      ) : null}
      <Typography
        component="div"
        variant="text-sm-semibold"
        color="neutral-700.main"
        display="inline"
        sx={{ marginLeft: "4px", verticalAlign: "text-bottom" }}
      >
        {"/person"}
      </Typography>
    </>
  );
};

export default TripCost;
