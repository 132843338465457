import { Card, Typography } from "@mui/material";
import TripDifficulty from "./trip.difficulty";
import PropTypes from "prop-types";
import React, { useState, useRef, forwardRef } from "react";
import { useSelector } from "react-redux";
import TripDetails from "./trip.detail.dialog";
import { Box } from "@mui/system";
import UITooltip from "../ui/tooltip";
import ALink from "../ui/link";
import useIsMobile from "../../utils/device.type.hook";
import { dateMonthAndWeekDay } from "../../utils/date";
import {
  ENTITY,
  EVENT_NAME,
  sendTripSnippetClickEvent,
  useEventOnScrollToComponent,
  useTripSnippetEventSource,
} from "../../utils/analytics";
import VerifiedIcon from "../Host/verified.icon";
import WomenOnlyChip from "./components/women.only.chip";
import TripDiscount from "./trip.discount";
import TripSeats from "./components/trip.seats";
import Image from "next/image";
import Chip from "../chip/chip";
import StarGreen from "../../public/images/icons/star_green.svg";
import styles from "./trip.snippet.module.css";

const MobileTripSnippet = forwardRef(({ children, ...props }, ref) => {
  return (
    <Card elevation={0} className={styles.tripSnippetCard} {...props} ref={ref}>
      {children}
    </Card>
  );
});

const DesktopTripSnippet = forwardRef(({ trip, children, onClick }, ref) => {
  return (
    <Card
      elevation={0}
      className={styles.tripSnippetCard}
      onClick={onClick}
      ref={ref}
    >
      <ALink
        href={`/${trip.urlPath}`}
        aProps={{ target: "_blank", style: { width: "100%" } }}
      >
        {children}
      </ALink>
    </Card>
  );
});

function TripSnippetUIRow({ children, className = "" }) {
  return (
    <Box className={[styles.tripSnippet__childRow, className].join(" ")}>
      {children}
    </Box>
  );
}

function TripSnippetUI({ trip, useFilterDestination = false, ...props }) {
  const filterDestinationLocation = useSelector(
    (state) => state.FilterReducer.destinationLocation.details
  );

  return (
    <Box {...props} className={styles.tripSnippet}>
      <Box className={styles.tripSnippet__topCtn}>
        {trip?.cardCoverImageUrl && (
          <Image
            src={trip.cardCoverImageUrl}
            layout="fill"
            className={styles.tripSnippet__topCtn__img}
          />
        )}
        <Box className={styles.tripSnippet__topCtn__innerCtn}>
          <TripSnippetUIRow>
            <Chip
              className={styles.tripSnippet__tripTypeChip}
              icon={
                <Image src={trip?.tripTypeImage} width="24px" height="24px" />
              }
              label={trip?.tripType}
            >
              <Image src={trip?.tripTypeImage} width="24px" height="24px" />
              {trip?.tripType}
            </Chip>
            <TripDiscount
              tripDetails={trip}
              className={styles.tripSnippet__discountChip}
            />
          </TripSnippetUIRow>
          <Box className={styles.tripSnippet__locationNameCtn}>
            <Typography className={styles.tripSnippet__destinationName}>
              {useFilterDestination && filterDestinationLocation?.id
                ? filterDestinationLocation.name
                : trip?.highlightLocation}
            </Typography>
            <Typography className={styles.tripSnippet__startingLocationName}>
              from {trip?.startingLocation}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={styles.tripSnippet__bottomCtn}>
        <TripSnippetUIRow>
          {!trip.isBooked && trip.discountPercentage ? (
            <>
              <Typography className={styles.tripSnippet__priceStrike}>
                {trip?.costCurrency ?? ""}
                {trip?.costPerPerson ?? ""}
              </Typography>
            </>
          ) : (
            <></>
          )}
          <Box
            className={[
              styles.tripSnippet__colGap8,
              styles.tripSnippet__durationDateCtn,
            ].join(" ")}
          >
            <Typography
              className={styles.tripSnippet__durationDateCtn__duration}
            >
              {trip?.durationStr}
            </Typography>
            <Typography
              className={styles.tripSnippet__durationDateCtn__divider}
            >
              &middot;
            </Typography>
            <Typography className={styles.tripSnippet__durationDateCtn__date}>
              {dateMonthAndWeekDay(trip.startDate, "-")}
            </Typography>
            {/* TODO?: <TripDifficulty
                difficulty={trip.difficulty}
                sx={{ cursor: "pointer" }}
              /> */}
          </Box>
        </TripSnippetUIRow>
        <TripSnippetUIRow>
          <Typography className={styles.tripSnippet__price}>
            {trip?.costCurrency ?? ""}
            {trip?.discountPercentage
              ? trip.discountedCostPerPerson
              : trip?.costPerPerson ?? ""}
          </Typography>
          <Box className={styles.tripSnippet__colGap8}>
            <TripSeats
              className={styles.tripSnippet__seatsChip}
              trip={trip}
              noWrap
            />
            {trip?.womenOnly && (
              <WomenOnlyChip
                className={styles.tripSnippet__womenOnlyChip}
                disableIcon
              />
            )}
          </Box>
        </TripSnippetUIRow>
        <TripSnippetUIRow
          className={styles.tripSnippet__bottomCtn__hostDetailsRow}
        >
          <Box
            className={[
              styles.tripSnippet__hostDetailsCtn,
              trip?.verifiedHost &&
                styles.tripSnippet__hostDetailsCtn__verifiedHost,
            ].join(" ")}
          >
            {trip?.verifiedHost && <VerifiedIcon />}
            {trip.hostTripCardImg && !trip?.verifiedHost && (
              <Box className={styles.tripSnippet__hostDetailsCtn__hostImgCtn}>
                <Image src={trip.hostTripCardImg} layout="fill" />
              </Box>
            )}
            <Typography className={styles.tripSnippet__hostName}>
              {trip?.hostName}
            </Typography>
          </Box>

          <Box className={styles.tripSnippet__colGap8}>
            {trip?.promotedTrip && (
              <Typography className={styles.tripSnippet__promotedTrip}>
                Promoted
              </Typography>
            )}

            <Box className={styles.tripSnippet__hostRatingCtn}>
              <Image width={16} height={16} src={StarGreen} />
              {!trip?.hostAverageRating && (
                <Box
                  className={styles.tripSnippet__hostRatingCtn__noRatingCtn}
                />
              )}
              <Typography className={styles.tripSnippet__hostRatingCtn__text}>
                {trip?.hostAverageRating || "-"}
              </Typography>
            </Box>
          </Box>
        </TripSnippetUIRow>
      </Box>
    </Box>
  );
}

export default function TripSnippet({
  trip,
  gaSource,
  useFilterDestination = false,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const snippetRef = useRef(null);
  const eventSource = useTripSnippetEventSource();

  useEventOnScrollToComponent(
    snippetRef,
    trip.host?.id,
    ENTITY.HOST,
    eventSource,
    EVENT_NAME.TRIP_CARD_IMPRESSION
  );

  function sendClickEvent() {
    sendTripSnippetClickEvent(trip.host?.id, eventSource, trip?.id);
  }

  const handleDialogOpen = () => {
    setDialogOpen(true);
    sendClickEvent();
  };

  const isMobile = useIsMobile();

  return (
    <>
      {isMobile && (
        <MobileTripSnippet onClick={handleDialogOpen} ref={snippetRef}>
          <TripSnippetUI
            trip={trip}
            useFilterDestination={useFilterDestination}
          />
        </MobileTripSnippet>
      )}
      {!isMobile && (
        <DesktopTripSnippet
          trip={trip}
          onClick={sendClickEvent}
          ref={snippetRef}
        >
          <TripSnippetUI
            trip={trip}
            useFilterDestination={useFilterDestination}
          />
        </DesktopTripSnippet>
      )}
      <TripDetails
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        hostId={trip.host?.id}
        tripHashId={trip.hashId}
        gaSource={gaSource}
      />
    </>
  );
}

TripSnippet.propTypes = {
  trip: PropTypes.object.isRequired,
};
