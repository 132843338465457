import React from "react";
import { useDispatch } from "react-redux";
import useIsMobile from "../../../utils/device.type.hook";
import useConnectWithHost from "../../chat/use.connect.host.helper";
import {
  CHAT_CONNECT_CONTEXT,
  getAboutTripWAMsgTemplate,
  getHostWAContactLink,
} from "../../Host/host";
import { Button, Typography } from "@mui/material";
import Image from "next/image";
import config from "../../../config";
import { SOURCE, sendContactHostClickEvent } from "../../../utils/analytics";
import { EVENTNAME, SECTION } from "../../google-analytics/constants";
import { captureEvent, captureGAWEvent } from "../../google-analytics/tag";
import MsgBubble from "../../../public/images/icons/msg_bubble.svg";
import MsgBubbleGreen from "../../../public/images/icons/msg_bubble_green.svg";
import { AUTH_CONTEXT, AUTH_TYPE } from "../../auth/login";
import styles from "../trip.module.css";

function ContactHostButton({
  tripDetails,
  source,
  buttonStyleClass,
  buttonTextTheme,
  iconOnly = false,
  iconVariant = "green", // || "white"
  handleContactHostClick = () => {},
}) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const contactHostOnWhatsapp =
    isMobile && tripDetails.host.whatsappFields.contactOnWhatsapp;
  const [connectWithHostHandler, isLoggedIn] = useConnectWithHost(
    tripDetails.host,
    CHAT_CONNECT_CONTEXT.TRIP,
    tripDetails
  );

  const whatsappMsgTemplateParams = contactHostOnWhatsapp
    ? {
        location: tripDetails.highlightLocation,
        date: tripDetails.startDate_str,
        tripLink: `${config.HOST}/${tripDetails.urlPath}`,
        hostHandle: tripDetails.hostInstagramHandle,
        tripId: tripDetails.id,
      }
    : null;

  const whatsappContactLink = contactHostOnWhatsapp
    ? getHostWAContactLink(
        tripDetails.host.whatsappFields.whatsappPhoneNumber,
        getAboutTripWAMsgTemplate(whatsappMsgTemplateParams)
      )
    : "";

  const handleContactClick = (e) => {
    e.stopPropagation();

    // source for this event when on trip detail page is `trip` and not `trip_detail`
    const ga_source =
      source == SOURCE.TRIP_DETAIL ? SECTION.TRIP_PAGE : SECTION.TRIP_DIALOG;

    let captureEventName = contactHostOnWhatsapp
      ? EVENTNAME.CONTACT_HOST_CLICK_WA
      : EVENTNAME.CONTACT_HOST_CLICK;

    if (isLoggedIn) {
      if (tripDetails?.host?.hasAccount) {
        captureEventName = EVENTNAME.CONTACT_HOST_CLICK_CHAT;
        connectWithHostHandler();
      } else {
        if (contactHostOnWhatsapp) {
          window.open(whatsappContactLink, "_blank");
        } else {
          window.open(
            `https://www.instagram.com/${tripDetails.hostInstagramHandle}`,
            "_blank"
          );
        }
      }

      sendContactHostClickEvent(
        tripDetails.host?.id,
        SOURCE.TRIP_DETAIL,
        tripDetails.id
      );
      captureEvent(captureEventName, ga_source, {
        id: tripDetails.host?.id,
        name: tripDetails.hostInstagramHandle,
      });
      captureGAWEvent(
        contactHostOnWhatsapp ? "contact-host-wa" : "contact-host"
      );
    } else {
      dispatch({
        type: "OPEN_LOGIN_MODAL",
        payload: {
          authType: AUTH_TYPE.REGISTER,
          authContext: AUTH_CONTEXT.CONTACT_HOST,
          hostDetails: tripDetails?.host,
        },
      });
      captureEvent(EVENTNAME.LOGIN_CLICK, ga_source);
      captureEvent(EVENTNAME.CONTACT_HOST_CLICK_SIGNOUT, ga_source);
    }

    handleContactHostClick();
  };

  return (
    <Button
      className={[
        styles.contactHostButton,
        styles.contactHostButton__connect,
        buttonStyleClass,
      ].join(" ")}
      variant="contained"
      onClick={handleContactClick}
      disableElevation
    >
      <Image
        className={styles.contactHostButton__msgIcon}
        src={iconVariant === "green" ? MsgBubbleGreen : MsgBubble}
        priority
      />
      {!iconOnly && (
        <Typography
          variant="text-base-semibold"
          component="div"
          color={buttonTextTheme || "primary"}
        >
          {tripDetails?.isBooked
            ? `Contact ${tripDetails?.hostName} - No seats left`
            : `Contact ${tripDetails.hostName}`}
        </Typography>
      )}
    </Button>
  );
}

export default ContactHostButton;
