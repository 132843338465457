import PropTypes from "prop-types";
import { Typography, Paper } from "@mui/material";
import Image from "next/image";

import styles from "./trip.module.css";
import GoodNetwork from "../../public/images/icons/good_network.svg";
import Cutlery from "../../public/images/icons/cutlery.svg";
import Hotel from "../../public/images/icons/hotel.svg";
import Transport from "../../public/images/icons/car.svg";
import Distance from "../../public/images/icons/distance.svg";
import UITooltip from "../ui/tooltip";

const InfoBox = ({ feature = {} }) => {
  return (
    <UITooltip title={feature?.description}>
      <Paper className={styles.tripDetail__infobox} elevation={0}>
        {getIconForInfo(feature?.type) ? (
          <Image
            src={getIconForInfo(feature?.type)}
            alt={"Icon for " + feature?.description}
            priority
          />
        ) : null}
        <Typography
          className={styles.tripDetail__infobox__text}
          component="div"
        >
          {feature?.name}
        </Typography>
      </Paper>
    </UITooltip>
  );
};

const getIconForInfo = (infoType) => {
  switch (infoType) {
    case "network":
      return GoodNetwork;
    case "meal":
      return Cutlery;
    case "stay":
      return Hotel;
    case "travel-expense":
      return Transport;
    case "distance":
      return Distance;
    default:
      return "";
  }
};

export default InfoBox;

InfoBox.propTypes = {
  feature: PropTypes.instanceOf(Object).isRequired,
};
