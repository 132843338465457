import React from "react";
import { Typography } from "@mui/material";
import styles from "./info.module.css";

function TripSeats({ trip, className = "" }) {
  if (trip.availableSeats === null || trip?.availableSeats > 4) {
    return <></>;
  }

  return (
    <Typography className={[styles.trip__availableSeats, className].join(" ")}>
      {!trip?.isBooked ? `${trip.availableSeats} seats left` : "Booked"}
    </Typography>
  );
}

export default TripSeats;
