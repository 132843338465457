import React from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import TripName from "./components/name";
import TripCost from "./components/cost";
import TripExpectation from "./components/expectation";
import TripInfo from "./components/info";
import TripHost from "./components/host";
import TripIternary from "./components/iternary";
import TripHighlightLocationDetail from "./components/location-detail";
import TripDifficulty from "./trip.difficulty";
import TripDiscount from "./trip.discount";
import { EVENTNAME, SECTION } from "../google-analytics/constants";
import { captureEvent } from "../google-analytics/tag";
import TripExperienceSection from "./components/experience.section";
import ShareTripButton from "./components/share.trip.button";
import BookingButton from "./components/booking.button";
import ContactHostButton from "./components/host.contact.button";
import Activities from "./components/activites";
import TripImageSlider from "./components/trip.image.slider";
import styles from "./trip.detail.dialog.module.css";
import ReviewSection from "../review/review.section";
import RatingPill from "./components/rating.pill";

const discountStyle = {
  labelSx: {
    fontSize: "10px",
  },
};

const MobileTripContentUI = ({ tripLoaded, tripDetails, source }) => {
  const handleLearnMoreClick = () => {
    captureEvent(EVENTNAME.LOCATION_LEARN_MORE_CLICK, source, {
      id: tripDetails.id,
      name: tripDetails.name,
    });
  };

  return (
    <>
      {tripLoaded === false ? (
        <Box className={styles.tripDetailsDialogCtn__loadingCtn}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            className={
              source == SECTION.TRIP_DIALOG
                ? styles.tripDetailsDialogCtn
                : styles.tripDetailsCtn
            }
          >
            <TripImageSlider tripDetails={tripDetails} />
            <Stack
              className={[
                styles.tripDetailsDialogCtn__titleCtn,
                styles.tripDetailsDialogCtn__item__pad,
                source !== SECTION.TRIP_DIALOG &&
                  styles.tripDetailsDialogCtn__titleCtn__top,
              ].join(" ")}
              direction="row"
              alignItems="flex-end"
              spacing={1}
            >
              <TripName name={tripDetails?.name} />
            </Stack>
            <Box
              className={[
                styles.tripDetailsDialogCtn__info,
                styles.tripDetailsDialogCtn__item__pad,
              ].join(" ")}
            >
              <TripCost tripDetails={tripDetails} />
              <Box className={styles.tripDetailsDialogCtn__pillCtn}>
                <TripDifficulty difficulty={tripDetails?.difficulty} />
                <RatingPill
                  rating={tripDetails?.reviewsAndRatings?.averageRating}
                />
                <TripDiscount
                  tripDetails={tripDetails}
                  labelSx={discountStyle.labelSx}
                />
                <ShareTripButton isMobile tripDetails={tripDetails} />
              </Box>
              <TripHighlightLocationDetail
                tripDetails={tripDetails}
                styles={{ marginTop: "8px" }}
              />
              <TripInfo tripDetails={tripDetails} />
              <TripHost
                tripDetails={tripDetails}
                newTab
                source={source}
                hideBookingAndContact
              />
              <TripExpectation tripDetails={tripDetails} />
            </Box>
            <Box className={styles.tripDetailsDialogCtn__item__pad}>
              <Activities
                className={styles.tripDetailsDialogCtn__experienceCtn}
                tripDetails={tripDetails}
              />
            </Box>
            <Box
              className={[
                styles.tripDetailsDialogCtn__itinerary,
                styles.tripDetailsDialogCtn__item__padLeft,
              ].join(" ")}
            >
              <TripIternary tripDetails={tripDetails} />
            </Box>
            <TripExperienceSection
              className={[
                styles.tripDetailsDialogCtn__experienceCtn,
                styles.tripDetailsDialogCtn__item__padLeft,
              ].join(" ")}
              tripDetails={tripDetails}
            />
            <ReviewSection
              ctnClassName={styles.tripDetailsDialogCtn__reviewsCtn}
              hostDetails={tripDetails?.host}
              reviewsAndRatings={tripDetails?.reviewsAndRatings}
            />
            <Box className={styles.tripDetailsDialogCtn__floatingContactCtn}>
              <BookingButton tripDetails={tripDetails} source={source} />
              <ContactHostButton
                tripDetails={tripDetails}
                source={source}
                buttonTextTheme="primary"
                buttonStyleClass={styles.tripDetailsDialogCtn__contactBtn}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default MobileTripContentUI;
