import React, { useState, useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import Image from "next/image";
import useIsMobile from "../../../utils/device.type.hook";
import { SECTION } from "../../google-analytics/constants";
import { SOURCE } from "../../../utils/analytics";
import MediaViewer, { SlickVideo } from "../../ui/media-viewer/media.viewer";
import playIcon from "../../../public/images/icons/play_icon.svg";
import ImageCarousel from "../../location/image.carousel";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import styles from "./experience.section.module.css";

const mobileSettings = {
  dots: false,
  speed: 500,
  infinite: false,
  focusOnSelect: false,
  arrows: false,
  slidesToShow: 1.2,
  slidesToScroll: 1,
};

function PlayIcon({ mediaItem }) {
  return (
    mediaItem?.media_type === "video" && (
      <Box className={styles.experience__mediaItem__playBtnCtn}>
        <Image src={playIcon} />
      </Box>
    )
  );
}

function DesktopMediaItem({ mediaItem, onClick, children = <></> }) {
  const isVideo = mediaItem?.media_type === "video";
  return (
    <Box className={styles.experience__mediaItemCtn} onClick={onClick}>
      <PlayIcon mediaItem={mediaItem} />
      {
        <Image
          layout="fill"
          src={
            (isVideo && mediaItem?.thumbnail_cdn_url) ||
            (!isVideo && mediaItem?.cdn_url)
          }
          alt={mediaItem?.caption}
        />
      }
      {children}
    </Box>
  );
}

function MobileInnerMediaItem({ mediaItem, onClick, children }) {
  return (
    <Box className={styles.experience__mediaSlider__mediaCtn}>
      <Image
        layout="fill"
        src={
          (mediaItem?.media_type === "image" && mediaItem?.cdn_url) ||
          (mediaItem?.media_type === "video" && mediaItem?.thumbnail_cdn_url)
        }
        onClick={onClick}
        alt={mediaItem.caption}
      />
      <PlayIcon mediaItem={mediaItem} />
      {children}
    </Box>
  );
}

function TripExperienceSection({
  tripDetails = {},
  className = "",
  carouselMode = false,
}) {
  const isMobile = useIsMobile();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const experienceMediaUnavailable =
    !tripDetails?.itineraryDetails?.experience_media ||
    tripDetails?.itineraryDetails?.experience_media?.length === 0;

  const handleFSOpen =
    (idxOpened = 0) =>
    () => {
      setIsFullScreen(true);
      setCurrentSlide(idxOpened);

      const eventData = {
        id: tripDetails.id,
        name: tripDetails.name,
      };
      let gaSource = SECTION.TRIP_PAGE;
      let eventSource = SOURCE.TRIP_DETAIL;

      if (isMobile) {
        gaSource = SECTION.TRIP_DIALOG;
        eventSource = SOURCE.TRIP_DIALOG;
      }

      // TODO
      // captureEvent(EVENTNAME.ITINERARY_CLICK, gaSource, eventData);
      // sendEvent(
      //   tripDetails.itineraryDetails?.id,
      //   ENTITY.ITINERARY,
      //   eventSource,
      //   EVENT_NAME.ITINERARY_CLICKS
      // );
    };

  function handleFSClose() {
    setIsFullScreen(false);
    setCurrentSlide(0);
  }

  function handleCarouselSlideChange(idx) {
    setCurrentSlide(idx);
  }

  if (isMobile && carouselMode) {
    if (experienceMediaUnavailable) {
      return (
        <ImageCarousel
          locationDetail={tripDetails?.highlightLocationFullDetail}
        />
      );
    }

    return (
      <>
        <Carousel
          showArrows
          showThumbs={false}
          showStatus={false}
          showIndicators
          infiniteLoop
          onChange={(idx) => handleCarouselSlideChange(idx)}
        >
          {tripDetails?.itineraryDetails?.experience_media?.map(
            (media, idx) => (
              <Box
                key={idx}
                className={styles.experience__mediaCarouselItemCtn}
              >
                {media?.media_type === "image" && (
                  <img src={media?.cdn_url} alt={media?.caption} />
                )}
                {media?.media_type === "video" && (
                  <SlickVideo
                    refClassName=".selected"
                    src={media?.cdn_url}
                    controls
                    play={currentSlide === idx}
                  />
                )}
              </Box>
            )
          )}
        </Carousel>
      </>
    );
  }

  if (experienceMediaUnavailable) {
    return <></>;
  }

  return (
    <>
      <Box className={[styles.experienceCtn, className].join(" ")}>
        <Box className={styles.experience__headerCtn}>
          <Typography component="h2" className={styles.experience__header}>
            Photos & videos from past trips
          </Typography>
          <Button
            className={styles.experience__headerCtn__seeMoreBtn}
            onClick={handleFSOpen(0)}
          >
            See all
          </Button>
        </Box>
        {isMobile && (
          <Slider {...mobileSettings}>
            {tripDetails?.itineraryDetails?.experience_media
              ?.slice(0, 2)
              .map((media, idx) => (
                <Box
                  key={idx}
                  className={
                    idx === 0
                      ? styles.experience__mediaSlider__outerCtn__first
                      : styles.experience__mediaSlider__outerCtn__second
                  }
                >
                  {idx > 0 ? (
                    tripDetails?.itineraryDetails?.experience_media
                      ?.slice(1, 4)
                      .map((mediaItem, j) => (
                        <>
                          <MobileInnerMediaItem
                            key={j}
                            mediaItem={mediaItem}
                            onClick={handleFSOpen(j + 1)}
                          >
                            {j > 1 && (
                              <Button
                                className={styles.experience__moreMediaBtn}
                                onClick={handleFSOpen(j + 1)}
                              >
                                {`+${
                                  tripDetails.itineraryDetails.experience_media
                                    .length - 3
                                }`}
                              </Button>
                            )}
                          </MobileInnerMediaItem>
                        </>
                      ))
                  ) : (
                    <MobileInnerMediaItem
                      mediaItem={media}
                      onClick={handleFSOpen(idx)}
                    />
                  )}
                </Box>
              ))}
          </Slider>
        )}
        {!isMobile && (
          <Box className={styles.experience__mediaCtn}>
            {tripDetails?.itineraryDetails?.experience_media
              ?.slice(0, 4)
              .map((media, idx) => (
                <DesktopMediaItem mediaItem={media} onClick={handleFSOpen(idx)}>
                  {idx === 3 && (
                    <Button
                      className={styles.experience__moreMediaBtn}
                      onClick={handleFSOpen(idx)}
                    >
                      {`+${
                        tripDetails.itineraryDetails.experience_media.length - 3
                      }`}
                    </Button>
                  )}
                </DesktopMediaItem>
              ))}
          </Box>
        )}
      </Box>
      <MediaViewer
        medias={tripDetails?.itineraryDetails?.experience_media}
        open={isFullScreen}
        onClose={handleFSClose}
        activeIdx={currentSlide}
        setActiveItemIdx={setCurrentSlide}
        renderDisplayedItemFn={(mediaItem) => {
          if (mediaItem?.media_type === "video") {
            return (
              <video
                className={styles.experience__mediaItemCtn}
                key={mediaItem?.id}
                controls
                alt={mediaItem?.caption}
                autoPlay
              >
                <source src={mediaItem?.cdn_url} />
              </video>
            );
          }

          return (
            <img
              className={styles.experience__mediaItemCtn}
              src={mediaItem?.cdn_url}
              alt={mediaItem?.caption}
            />
          );
        }}
        renderMobileItem={(mediaItem, idx) => {
          if (mediaItem?.media_type === "video") {
            return (
              <SlickVideo
                key={mediaItem?.id}
                src={mediaItem?.cdn_url}
                play={idx === currentSlide}
                className={styles.experience__mediaItemCtn__slickVideo}
              />
            );
          }

          return (
            <img
              className={styles.experience__mediaItemCtn}
              src={mediaItem?.cdn_url}
              alt={mediaItem?.caption}
            />
          );
        }}
      />
    </>
  );
}

export default TripExperienceSection;
